import {
  Body,
  BodyProps,
  Box,
  BoxProps,
  ButtCon,
  Button,
  Card,
  Heading,
  Icon,
  TextInput,
  Tooltip
} from '@biom3/react';
import { StockStatusEnum } from 'lib/woocomerce/__generated__/graphql';
import { Product, ProductCollectionType } from 'lib/woocomerce/types';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import { CardFrame } from 'src/components/product-card/card-frame';
import { useProductsContext } from 'src/context/products-context';
import { useStore } from 'src/context/store-context';
import { useConnectWidget } from 'src/hooks/use-connect-widget';
import { useQuantity } from 'src/hooks/use-quantity';
import { PRICING_FIXED_DECIMALS } from 'src/utils';
import { toFormatedCurrency } from 'src/utils/misc';
import { getProductAttributes } from '../../utils/product-attributes';

export type ProductCardProps = {
  product: Product;
  onClick: (product: Product, qty: number) => void; // eslint-disable-line no-unused-vars
};

const StockStatus = ({ stockQty, product }: { product: Product; stockQty: number }) => {
  const [{ saleEnded }] = useStore();

  const unlimitedStock =
    product.stockQuantity === null && product.stockStatus === StockStatusEnum.InStock;
  const stockLabelA = unlimitedStock ? 'In stock' : `${stockQty} remaining`;
  const stockLabelB = stockQty > 0 ? stockLabelA : 'Out of stock';
  const label = saleEnded ? 'sale ended' : stockLabelB;

  return (
    <Box
      sx={{
        borderRadius: 'base.spacing.x1',
        backgroundColor: 'base.color.translucent.emphasis.300',
        px: 'base.spacing.x4',
        py: 'base.spacing.x2',
        userSelect: 'none'
      }}
    >
      {label}
    </Box>
  );
};

const NotTradableTag = () => {
  return (
    <Tooltip>
      <Tooltip.Target>
        <Body
          size="small"
          weight="bold"
          sx={{
            px: 'base.spacing.x1',
            brad: 'base.borderRadius.x1',
            color: 'base.color.text.body.inverse.primary',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            userSelect: 'none',
            bg: 'base.color.accent.8',
            '&:hover': {
              filter: 'brightness(0.9)'
            }
          }}
        >
          Not tradable
          <Icon
            icon="InformationCircle"
            sx={{
              ml: 'base.spacing.x1',
              fill: 'inherit',
              width: 'base.spacing.x4',
              height: 'base.spacing.x4'
            }}
          />
        </Body>
      </Tooltip.Target>
      <Tooltip.Content>
        <b>These are not NFT’s and will not show up in your wallet. </b>
        We recommend buying in-game assets with Passport.
      </Tooltip.Content>
    </Tooltip>
  );
};

const Title = ({ product, tradable }: { product: Product; tradable: boolean }) => {
  return (
    <Box sx={{ paddingBottom: 'base.spacing.x2', display: 'flex', alignItems: 'center' }}>
      <Heading size="small" sx={{ userSelect: 'none', mr: 'base.spacing.x2' }}>
        {product.name}
      </Heading>
      {!tradable ? <NotTradableTag /> : undefined}
    </Box>
  );
};

const CurrencyDisplay = ({ name, size }: { name: string; size: BodyProps['size'] }) => {
  const symbol = (name === 'WIMX' ? 'IMX' : name).toLowerCase();
  const symbolURL = `https://biome.immutable.com/hosted-assets/currency-icons/currency--${symbol}.svg`;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <Box
        rc={<Image width={16} height={16} src={symbolURL} alt={name} />}
        sx={{
          mr: 'base.spacing.x1'
        }}
      />
      <Body size={size} weight="regular" sx={{ pr: 'base.spacing.x1', color: 'inherit' }}>
        {name}
      </Body>
    </Box>
  );
};

const Price = ({
  price,
  markdownPrice,
  discounted,
  currency,
  highlighted
}: {
  price: number;
  markdownPrice: number;
  discounted: boolean;
  currency: string;
  highlighted: boolean;
}) => {
  const copySize: BodyProps['size'] = highlighted
    ? ['medium', 'medium', 'large', 'medium', 'large']
    : ['small', 'small', 'medium', 'small', 'medium'];
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: highlighted ? 'base.color.text.body.primary' : 'base.color.text.body.secondary'
        }}
      >
        <CurrencyDisplay name={currency} size={copySize} />
        <Body size={copySize} weight="bold" sx={{ color: 'inherit' }}>
          {toFormatedCurrency(price)}
        </Body>
        {discounted && (
          <Body
            sx={{
              px: 'base.spacing.x2',
              textDecoration: 'base.font.decoration.lineThrough',
              color: 'base.color.text.body.secondary'
            }}
            size={copySize}
          >
            &nbsp;{toFormatedCurrency(markdownPrice)}&nbsp;
          </Body>
        )}
      </Box>
    </Box>
  );
};

const Pricing = ({
  product,
  quantity,
  bundleSize
}: {
  product: Product;
  quantity: number;
  bundleSize: number;
}) => {
  const discounted = product.pricing.regularPrice !== product.pricing.price;

  const prices = [
    {
      price: product.pricing.price,
      currency: product.pricing.currency,
      regularPrice: product.pricing.regularPrice,
      salePrice: product.pricing.salePrice
    },
    ...(product.pricing.alternateCurrencies || [])
  ];

  return (
    <>
      {prices.map(({ price: basePrice, currency, regularPrice, salePrice }, index) => {
        const comparisonPrice = Number(
          basePrice === salePrice ? regularPrice : salePrice || regularPrice
        );

        const price = (Number(basePrice) * quantity * bundleSize).toFixed(PRICING_FIXED_DECIMALS);
        const markdownPrice = comparisonPrice * quantity * bundleSize;

        return (
          <Price
            key={currency}
            price={Number(price)}
            currency={currency}
            discounted={discounted}
            markdownPrice={markdownPrice}
            highlighted={index === 0}
          />
        );
      })}
    </>
  );
};

const StoreDiscount = ({ product, bundleSize }: { product: Product; bundleSize: number }) => {
  const { salePrice, regularPrice } = product.pricing;
  const { storeDiscount } = useProductsContext();
  const extraDiscount = salePrice && storeDiscount;
  const retailPrice = Number(regularPrice) * bundleSize;

  if (!storeDiscount) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-start',
        mb: 'base.spacing.x3'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          px: 'base.spacing.x2',
          py: 'base.spacing.x1',
          brad: 'base.borderRadius.x1',
          bg: 'base.color.brand.1'
        }}
      >
        <Body
          size="small"
          weight="bold"
          sx={{
            color: 'base.color.text.body.inverse.primary'
          }}
        >
          {extraDiscount && 'Extra'} {storeDiscount}% off applied
        </Body>
      </Box>
      {extraDiscount && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            px: 'base.spacing.x2',
            py: 'base.spacing.x1',
            brad: 'base.borderRadius.x1',
            bg: 'base.color.brand.1',
            ml: 'base.spacing.x2'
          }}
        >
          <Body
            size="small"
            weight="bold"
            sx={{
              color: 'base.color.text.body.inverse.primary'
            }}
          >
            RRP {toFormatedCurrency(retailPrice)}
          </Body>
        </Box>
      )}
    </Box>
  );
};

const QuantityInput = ({
  quantity,
  maxQuantity,
  setQuantity,
  bundleSize,
  bundleQuantity
}: {
  quantity: number;
  bundleSize: number;
  bundleQuantity: number;
  maxQuantity: number;
  setQuantity: (qty: number) => void; // eslint-disable-line no-unused-vars
}) => {
  const [inputQty, setInputQty] = useState(bundleQuantity);
  const [focused, setFocused] = useState(false);

  const maxQty = Math.floor(maxQuantity / (bundleSize || 1));

  useEffect(() => {
    setInputQty(bundleQuantity);
  }, [bundleQuantity]);

  if (!focused) {
    return (
      <Body
        size="medium"
        weight="bold"
        onClick={() => setFocused(true)}
        sx={{
          px: 'base.spacing.x4',
          cursor: 'pointer',
          mx: 'base.spacing.x1',
          '&:hover': {
            color: 'base.color.text.body.primary',
            borderRadius: 'base.borderRadius.x4',
            outlineStyle: 'solid',
            outlineColor: 'base.color.text.body.secondary',
            outlineWidth: 'base.border.size.100',
            outlineOffset: '-4px',
            bg: 'base.color.translucent.emphasis.300',
            py: 'base.spacing.x2'
          }
        }}
      >
        {bundleQuantity}
      </Body>
    );
  }

  const valid = inputQty !== bundleQuantity && inputQty > 0 && inputQty <= maxQty;

  const onDone = () => {
    setFocused(false);

    if (valid) {
      const newQty = inputQty * bundleSize;
      const deltaQty = Math.abs(quantity - newQty) * (bundleQuantity > inputQty ? -1 : 1);
      setQuantity(deltaQty);
    }
    setInputQty(inputQty);
  };

  return (
    <Body size="medium" weight="bold" sx={{ px: 'base.spacing.x2' }}>
      <TextInput
        autoFocus
        sizeVariant="medium"
        value={inputQty}
        hideClearValueButton
        onChange={(e) => {
          const value = Number(e.target.value);
          if (!isNaN(value) && value >= 0 && value <= maxQty) {
            setInputQty(value);
          }
        }}
        onBlur={onDone}
        sx={{ minWidth: 'base.spacing.x25' }}
      >
        <TextInput.StatefulButtCon
          icon={valid ? 'Tick' : 'Close'}
          state="initial"
          sx={{
            minWidth: 'auto',
            width: 'base.spacing.x6',
            height: 'base.spacing.x6'
          }}
        />
      </TextInput>
    </Body>
  );
};

const Quantity = ({
  stockQty,
  quantity,
  bundleSize,
  setQuantity,
  maxQuantity,
  exceededQuantity
}: {
  product: Product;
  quantity: number;
  bundleSize: number;
  stockQty: number;
  maxQuantity: number;
  exceededQuantity: boolean;
  setQuantity: (qty: number) => void; // eslint-disable-line no-unused-vars
}) => {
  const { allowlisted } = useProductsContext();
  const bundleQuantity = Math.floor(quantity / (bundleSize || 1));
  const willExceedMaxQuantity = quantity + bundleSize > maxQuantity;
  const overMaxQuantity = bundleQuantity === 1 && (exceededQuantity || willExceedMaxQuantity);
  const notAvailable = stockQty <= 0 || overMaxQuantity || !allowlisted;

  if (notAvailable) return null;

  return (
    <Box
      sx={{
        display: 'flex',
        flex: 1 / 2,
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <ButtCon icon="Minus" variant="tertiary" onClick={() => setQuantity(-1 * bundleSize)} />
      <QuantityInput
        quantity={quantity}
        bundleQuantity={bundleQuantity}
        setQuantity={setQuantity}
        bundleSize={bundleSize}
        maxQuantity={maxQuantity}
      />
      <ButtCon icon="Add" variant="tertiary" onClick={() => setQuantity(+1 * bundleSize)} />
    </Box>
  );
};

const htmlDescriptionStyles: BoxProps['sx'] = {
  a: {
    color: 'base.color.text.link.primary'
  },
  'a:hover': {
    textDecoration: 'base.font.decoration.underline'
  }
};

const HtmlDescription = ({ html }: { html: string }) => {
  return (
    <Box
      sx={{ ...htmlDescriptionStyles, paddingBottom: 'base.spacing.x8' }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
};
const Description = ({
  product,
  quantity,
  bundleSize
}: {
  product: Product;
  quantity: number;
  bundleSize: number;
}) => {
  return (
    <Box sx={{ paddingBottom: 'base.spacing.x6', userSelect: 'none' }}>
      <HtmlDescription html={product.description} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-end'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'flex-start'
          }}
        >
          <StoreDiscount product={product} bundleSize={bundleSize} />
          <Pricing
            product={product}
            quantity={Math.floor(quantity / bundleSize)}
            bundleSize={bundleSize}
          />
        </Box>
      </Box>
    </Box>
  );
};

const BuyNow = (props: {
  product: Product;
  onClick: () => void;
  stockQty: number;
  tradable: boolean;
}) => {
  const { stockQty, onClick, product } = props;
  const [{ saleEnded }] = useStore();
  const { allowlisted } = useProductsContext();
  const { connectWallet, connected } = useConnectWidget();

  const inStock = product.stockStatus === StockStatusEnum.InStock;
  const outOfStock = stockQty <= 0 && !inStock;
  const notAvailable = outOfStock || !allowlisted || saleEnded;

  if (notAvailable) {
    let label = '';
    if (saleEnded) {
      label = 'Pre-sale has finished';
    } else if (!allowlisted) {
      label = 'Sorry this item is unavailable';
    } else if (outOfStock) {
      label = 'Sold out';
    }

    return (
      <Body
        weight="bold"
        size="medium"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          backgroundColor: 'base.color.translucent.emphasis.300',
          borderRadius: 'base.spacing.x1',
          px: 'base.spacing.x2',
          py: 'base.spacing.x4'
        }}
      >
        {label}
      </Body>
    );
  }

  const handleOnClick = () => {
    if (connected) {
      onClick();
      return;
    }

    connectWallet();
  };

  return (
    <Button sx={{ flex: 1, mt: 'base.spacing.x4', whiteSpace: 'nowrap' }} onClick={handleOnClick}>
      Buy now
    </Button>
  );
};

export const ProductCard = ({ product, onClick }: ProductCardProps) => {
  const { maxQuantity, bundleSize, stockQty, attributes } = getProductAttributes(product);
  const [productQty, setProductQty, exceededQuantity] = useQuantity(bundleSize, maxQuantity);

  const tradable = product.collectionType !== ProductCollectionType.WEB2;

  return (
    <CardFrame attributes={attributes}>
      <Card
        rc={<div />}
        sx={{
          cursor: 'auto',
          aspectRatio: ['initial', 'initial', 'initial', 17 / 25],
          '& .textContainer': {
            position: 'initial'
          }
        }}
      >
        {product.image?.sourceUrl && (
          <Card.AssetImage
            sx={{
              pointerEvents: 'none',
              userSelect: 'none'
            }}
            aspectRatio="7:5"
            responsiveSizes={[640, 860, 1440]}
            imageUrl={product.image.sourceUrl}
          />
        )}
        <Card.Caption
          sx={{
            position: 'absolute',
            top: 'base.spacing.x4',
            right: 'base.spacing.x4'
          }}
        >
          <StockStatus product={product} stockQty={stockQty} />
        </Card.Caption>
        <Card.Title>
          <Title product={product} tradable={tradable} />
        </Card.Title>
        <Card.Description>
          <Description product={product} quantity={productQty} bundleSize={bundleSize} />
          <Box
            sx={{
              display: 'flex',
              flex: 1,
              flexWrap: 'wrap',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-end'
            }}
          >
            <Quantity
              product={product}
              stockQty={stockQty}
              quantity={productQty}
              setQuantity={setProductQty}
              maxQuantity={maxQuantity}
              exceededQuantity={exceededQuantity}
              bundleSize={bundleSize}
            />
            <Box sx={{ width: 'base.spacing.x4', height: 'base.spacing.x4' }} />
            <BuyNow
              product={product}
              tradable={tradable}
              stockQty={stockQty}
              onClick={() => {
                onClick(product, productQty);
              }}
            />
          </Box>
        </Card.Description>
      </Card>
    </CardFrame>
  );
};
