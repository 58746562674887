import {
  Box,
  BoxProps,
  Button,
  ColorProperties,
  GetNarrowedDesignTokens,
  IconProps,
  LogoProps
} from '@biom3/react';
import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useCallback, useEffect, useState } from 'react';
import { useConnectWidget } from '../hooks/use-connect-widget';
import { useWalletWidget } from '../hooks/use-wallet-widget';
import { WALLET_WIDGETS_ROOT, delay, truncate } from '../utils';

type IconState = {
  icon: IconProps['icon'];
  fill: GetNarrowedDesignTokens<ColorProperties>;
};

export const ConnectWallet = (props: BoxProps) => {
  const { openWallet, walletOpen, walletProviderName } = useWalletWidget();
  const { connectWallet, connecting, connected, walletAddress } = useConnectWidget();

  const busy = walletOpen || connecting;

  const [icon, setIcon] = useState<IconState>({
    icon: 'Wallet',
    fill: 'currentColor'
  });

  let copy = 'Connect Wallet';
  let logo: LogoProps['logo'] = 'WalletConnectSymbol';

  if (walletProviderName === ImtblCheckout.WalletProviderName.PASSPORT) {
    logo = 'PassportSymbol';
  }

  if (walletProviderName === ImtblCheckout.WalletProviderName.METAMASK) {
    logo = 'MetaMaskSymbol';
  }

  if (connecting) {
    copy = 'Connecting...';
  }

  if (connected) {
    copy = 'Your Balances';
  }

  if (walletOpen && walletAddress) {
    copy = truncate(walletAddress);
  }

  useEffect(() => {
    if (connecting === false && connected === false) {
      setIcon((prev) => ({ ...prev, icon: 'Wallet' }));
    }

    if (connecting === true && connected === false) {
      setIcon((prev) => ({ ...prev, icon: 'Loading' }));
    }

    if (connected === true && connecting === false) {
      setIcon({ icon: 'TickWithCircle', fill: 'base.color.accent.1' });
      (async () => {
        await delay(1.5);
        setIcon({ icon: 'Wallet', fill: 'currentColor' });
      })();
    }
  }, [connected, connecting, walletAddress]);

  const onWalletClick = useCallback(() => {
    (connected ? openWallet : connectWallet)?.();
  }, [connected, connectWallet, openWallet]);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'flex-end',
        ...props.sx
      }}
      {...props}
    >
      <Button
        onClick={onWalletClick}
        variant="secondary"
        sx={{
          pointerEvents: busy ? 'none' : 'inherit',
          width: ['100%', '100%', '100%', 'auto']
        }}
      >
        {!walletOpen && (
          <Button.Icon
            icon={icon.icon}
            sx={{
              fill: icon.fill,
              width: 'base.icon.size.250',
              transition: 'fill 0.3s ease-in-out'
            }}
          />
        )}
        {walletOpen && walletAddress && logo && (
          <Button.Logo logo={logo} sx={{ width: 'base.icon.size.250' }} />
        )}
        {copy}
      </Button>
      <Box
        rc={<span id={WALLET_WIDGETS_ROOT} />}
        sx={{
          zIndex: 5,
          position: ['fixed', 'fixed', 'absolute'],
          top: ['0', 'base.spacing.x26', 'base.spacing.x16'],
          right: ['50%', '50%', '50%', '0'],
          transform: [
            'translate3d(50%, 0, 0)',
            'translate3d(50%, 0, 0)',
            'translate3d(50%, 0, 0)',
            'none'
          ]
        }}
      />
    </Box>
  );
};
