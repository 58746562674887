import { ConfirmationScreen } from './__generated__/graphql';

export type {
  Banner,
  SimpleProductFragment as Product,
  ConfirmationScreen,
  MaintenanceMode as MaintenanceSettings
} from './__generated__/graphql';

export enum ProductCollectionType {
  WEB2 = 'WEB2',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155'
}

export type ConfirmationScreenMap = {
  [key: string]: ConfirmationScreen;
};

export enum ConfirmationScreenWallet {
  PASSPORT = 'passport',
  OTHER = 'other'
}

export enum ConfirmationScreenCollectionType {
  WEB2 = 'web2',
  WEB3 = 'web3'
}

export enum ConfirmationScreenCTA {
  LAUNCH_GAME = 'launch_game',
  PASSPORT = 'passport',
  NONE = 'none'
}
