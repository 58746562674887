import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useEffect, useRef, useState } from 'react';
import { useStore } from 'src/context/store-context';
import { useWidgets } from 'src/context/widgets-context';
import { notifyError } from '../utils';
import { useBridgeWidget } from './use-bridge-widget';
import { useOnRampWidget } from './use-onramp-widget';
import { useSwapWidget } from './use-swap-widget';

export type SaleWidgetParams = ImtblCheckout.WidgetParameters[typeof ImtblCheckout.WidgetType.SALE];

export type UseSaleWidgetProps = {
  params?: SaleWidgetParams;
  config?: ImtblCheckout.WidgetProperties<typeof ImtblCheckout.WidgetType.SALE>;
  onClose?: () => void;
};
export const useSaleWidget = ({ params, config, onClose }: UseSaleWidgetProps) => {
  const [{ factory, saleWidget }, widgetsDispatch] = useWidgets();
  const [{ selectedProduct }, storeDispatch] = useStore();
  const { bridgeWidget } = useBridgeWidget();
  const { swapWidget } = useSwapWidget();
  const { onRampWidget } = useOnRampWidget();

  const [saleInProgress, setSaleInProgress] = useState(false);
  const secondaryFlowInProgress = useRef<Boolean>(false);

  const mountSaleWidget = (node: HTMLElement | null) => {
    if (node !== null && node.id !== undefined) {
      handleMount(node.id);
    } else {
      setTimeout(() => mountSaleWidget(node), 300);
    }
  };

  const handleBridgeRequest = (id: string, params: ImtblCheckout.RequestBridgeEvent) => {
    bridgeWidget?.mount(id, params);
  };

  const handleOnRampRequest = (id: string, params: ImtblCheckout.RequestOnrampEvent) => {
    onRampWidget?.mount(id, params);
  };

  const handleSwapRequest = (id: string, params: ImtblCheckout.RequestSwapEvent) => {
    swapWidget?.mount(id, params);
  };

  const handleMountSecondaryFlow = (mountWidget: () => void) => {
    secondaryFlowInProgress.current = true;
    setSaleInProgress(false);
    saleWidget?.unmount();
    mountWidget();
  };

  const handleUnMountSecondaryFlow = (id: string) => {
    if (!secondaryFlowInProgress.current) return;
    setSaleInProgress(true);
    saleWidget?.mount(id, params);
    secondaryFlowInProgress.current = false;
  };

  const handleMount = (id: string) => {
    if (!saleWidget || !bridgeWidget || !swapWidget || !onRampWidget) return;

    saleWidget.addListener(ImtblCheckout.SaleEventType.CLOSE_WIDGET, handleUnMount);

    saleWidget.addListener(
      ImtblCheckout.SaleEventType.SUCCESS,
      (result: ImtblCheckout.SaleSuccess) => {
        storeDispatch({ payload: { type: 'RESET_SELECTED_PRODUCT' } });
        storeDispatch({
          payload: { type: 'SET_SALE_RESULT', successful: true, result, product: selectedProduct! }
        });
      }
    );

    saleWidget.addListener(
      ImtblCheckout.SaleEventType.REQUEST_BRIDGE,
      (params: ImtblCheckout.RequestBridgeEvent) => {
        handleMountSecondaryFlow(() => handleBridgeRequest(id, params));
      }
    );

    saleWidget.addListener(
      ImtblCheckout.SaleEventType.REQUEST_SWAP,
      (params: ImtblCheckout.RequestSwapEvent) => {
        handleMountSecondaryFlow(() => handleSwapRequest(id, params));
      }
    );

    saleWidget.addListener(
      ImtblCheckout.SaleEventType.REQUEST_ONRAMP,
      (params: ImtblCheckout.RequestOnrampEvent) => {
        handleMountSecondaryFlow(() => handleOnRampRequest(id, params));
      }
    );

    bridgeWidget.addListener(ImtblCheckout.BridgeEventType.CLOSE_WIDGET, () => {
      handleUnMountSecondaryFlow(id);
    });

    swapWidget.addListener(ImtblCheckout.SwapEventType.CLOSE_WIDGET, () => {
      handleUnMountSecondaryFlow(id);
    });

    onRampWidget.addListener(ImtblCheckout.OnRampEventType.CLOSE_WIDGET, () => {
      handleUnMountSecondaryFlow(id);
    });

    setSaleInProgress(true);
    saleWidget.mount(id, params);
  };

  const handleUnMount = () => {
    if (!saleWidget || !bridgeWidget || !swapWidget || !onRampWidget) return;

    saleWidget.removeListener(ImtblCheckout.SaleEventType.SUCCESS);
    saleWidget.removeListener(ImtblCheckout.SaleEventType.CLOSE_WIDGET);

    setSaleInProgress(false);
    saleWidget?.unmount();
    onClose?.();
  };

  useEffect(() => {
    // create sale widget
    if (!factory || saleWidget) return;

    (async () => {
      try {
        const widget = factory.create(ImtblCheckout.WidgetType.SALE, config);
        widgetsDispatch({ payload: { type: 'SET_WIDGET', widget: { saleWidget: widget } } });
      } catch (error) {
        notifyError(error);
      }
    })();
  }, [factory, saleWidget, widgetsDispatch, config]);

  return {
    saleWidget,
    saleInProgress,
    mountSaleWidget
  };
};
