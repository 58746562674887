import { checkout as ImtblCheckout } from '@imtbl/sdk';
import { useCallback, useEffect, useRef } from 'react';
import { useAnalytics } from 'src/context/analytics-context';
import { useStore } from 'src/context/store-context';
import { useWidgets } from 'src/context/widgets-context';
import { getExtras } from 'src/utils/analytics';
import {
  ConfirmationScreenCollectionType,
  ConfirmationScreenWallet,
  ProductCollectionType
} from '../../../lib/woocomerce/types';
import { ConfirmationCard } from './confirmation-card';

export const OrderConfirmation = () => {
  const isOpen = useRef<boolean>(false);
  const [{ saleResult, confirmationScreens, walletAddress }, dispatch] = useStore();
  const [{ walletProviderName, userInfo }] = useWidgets();
  const { page } = useAnalytics();

  const isPassport = walletProviderName === ImtblCheckout.WalletProviderName.PASSPORT;

  const handleClose = useCallback(
    (reset: boolean = false) => {
      if (!isOpen.current) return;
      isOpen.current = false;
      dispatch({ payload: { type: 'SET_TOGGLE_MODAL_OPEN', open: false } });

      if (reset) {
        dispatch({ payload: { type: 'RESET_SELECTED_PRODUCT' } });
        dispatch({ payload: { type: 'RESET_SALE_RESULT' } });
      }
    },
    [dispatch]
  );

  const trackOrderConfirmation = () => {
    page({
      userJourney: 'ViewPage',
      screen: 'OrderConfirmation',
      extras: {
        tokenIds: saleResult?.tokenIds,
        transactionId: saleResult?.transactionId,
        transactions: saleResult?.transactions,
        ...getExtras(saleResult.product, walletAddress, walletProviderName, userInfo)
      }
    });
  };

  useEffect(
    () => {
      // Mount order confirmation after successful sale
      if (!saleResult.successful || !saleResult.product || isOpen.current) return;
      isOpen.current = true;

      trackOrderConfirmation();

      const confirmationWallet = isPassport
        ? ConfirmationScreenWallet.PASSPORT
        : ConfirmationScreenWallet.OTHER;
      const confirmationCollectionType =
        saleResult.product?.collectionType !== ProductCollectionType.WEB2
          ? ConfirmationScreenCollectionType.WEB3
          : ConfirmationScreenCollectionType.WEB2;

      const confirmationScreen = confirmationScreens
        ? confirmationScreens[`${confirmationCollectionType}-${confirmationWallet}`]
        : undefined;

      dispatch({
        payload: {
          type: 'SET_MODAL_CONTENT',
          open: true,
          onModalClose: () => handleClose(false),
          content: (
            <ConfirmationCard
              onClose={handleClose}
              product={saleResult.product}
              screenData={confirmationScreen}
            />
          )
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, handleClose, isPassport, saleResult]
  );

  return null;
};
